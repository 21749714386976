const {
  BarChart,
  Bar,
  XAxis,
  Label,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} = window["Recharts"];

function fillMissingDates(data, startDate, endDate, offSetTime) {
  const newData = [];

  while (startDate <= endDate) {
    const dateString = startDate.toISOString().slice(0, 10); 
    const existingData = data.find((item) => {               
      let frontendTZ = new Date(item.day);
      frontendTZ = new Date(frontendTZ.getTime() - offSetTime * 60 * 1000 - frontendTZ.getTimezoneOffset() * 60 * 1000);
      frontendTZ = new Date(frontendTZ); 
      const modifiedDate = frontendTZ.toISOString().slice(0, 10);  
      return modifiedDate === dateString;                  
    });
    if (existingData) {
      newData.push({
        day: existingData.day,
        returnCount: existingData.returnCount,
        createCount: existingData.createCount,
      });
    } else {
      newData.push({ day: dateString, returnCount: 0, createCount: 0 });
    }

    startDate = new Date(startDate.setDate(startDate.getDate() + 1));
  }

  return newData;
}

function ReportChart(props) {
  let { dateTime, users, lang, formatDateCustom, offsetServer} = props;
  const offSetTime = offsetServer; 
  let startDate = new Date(dateTime.start_date);
  let endDate = new Date(dateTime.end_date);


  const user = _.cloneDeep(users) || [];
  const filledData = fillMissingDates(user, startDate, endDate, offSetTime);

  let data = filledData.map((val, index) => {
    return {
      name: formatDateCustom(val.day, "DD MMM"),
      [lang.lang.__New_chats]: val.createCount || 0,
      [lang.lang.__Existing_chats]: val.returnCount || 0,
    };
  });

  return (
    <div style={{ paddingRight: "8%", marginTop: 30 }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis fontSize={14} dataKey="name" type="category">
            {!user.length && (
              <Label
                value={lang.lang.__no_data}
                position="center"
                style={{
                  transform: `translate(0px, -50%)`,
                  color: "#ABABAD",
                  fontStyle: "italic",
                }}
              />
            )}
          </XAxis>
          <YAxis fontSize={14} />
          <Tooltip cursor={{ fill: "#f2f8ff99" }} />
          <Legend />
          <Bar
            stackId="a"
            dataKey={lang.lang.__Existing_chats}
            fill="#65A6FF"
          />
          <Bar stackId="a" dataKey={lang.lang.__New_chats} fill="#27DB7A" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
